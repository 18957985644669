import { createRouter, createWebHashHistory } from 'vue-router'
const login = () => import('@/views/Login/index')
const service = () => import('@/views/service/index')
const routes = [
  {
    path: '/',
    component: login
  },
  {
    path: '/service',
    component: service
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
